.main-page-content {
  display: flex;
  position: relative;
  height: fit-content;
  width: 100%;
  flex-direction: column;
  padding-bottom: 8rem;
  row-gap: 2rem;
  align-items: center;
  overflow: hidden;
  z-index: 2;
}
.home {
  display: flex;
  padding-top: 15rem;
}
.section {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
  row-gap: 2rem;
  max-width: 35rem;
  align-items: center;
  position: relative;
}
.section-name {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 1.5rem;
  line-height: 0%;
  background: var(--linear-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.top {
  height: 0%;
  width: 0%;
}
