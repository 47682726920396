:root {
  // colors
  --white: #ffffff;
  --black: #000000;
  --bgColor: rgba(0, 0, 0, 0.5);
  --trackColor: #e7e7e7;
  --lightGrey: #c8c8c8;
  --iconSize: 30px;
  --navIconSize: 25px;
  --iconColor: rgb(255, 255, 255);

  // glass card
  --glassBgColor: rgba(255, 255, 255, 0);

  // other
  --doc-height: 100%;
  --shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  --topBarHeight: 5rem;
  --hoverAnimation: all 175ms ease-in-out;
  --buttonPadding: 0.3rem 1.2rem 0.3rem 1.2rem;
  --borderRadius: 16px;
  --border: 2px solid var(--borderCard);
}
::-webkit-scrollbar {
  height: 4px;
  width: 4px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background-color: transparent;
  /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: var(--white); /* color of the scroll thumb */
  border-radius: 2px;
}
* {
  box-sizing: border-box;
  outline: none;
}
html,
body {
  --text-color: #333333;
  --background-color: #000000;
  --active-color: #ffffff;
  --primary: #28979f;
  --secondary: #28979f;
  --tertiary: #4eb26a;
  --button-color: #ffffff;
  --iconColor: #ffffff;
  --menu-icon-color: #333333;
  --shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  --mode-opacity: 0.6;
  --card-color: rgba(221, 221, 221, 0.7);
  --company-bg: brightness(0) invert(1);
  --swiper-pagination-color: var(--secondary);
  --linear-gradient: var(--white);
  --input-bg: rgba(0, 0, 0, 0.05);
  --color-env: #28979f;
  --color-env2: #26898e;
  --color-flap: #13474b;
  --color-bg: #35c5cf;
  --headshot-bg: linear-gradient(var(--color-bg), var(--color-env2));
  margin: 0;
  padding: 0;
  background-color: var(--background-color) !important;
  padding: 0;
}
body.dark {
  --text-color: #ffffff;
  --background-color: #000000;
  --active-color: #ffffff;
  --primary: #1f1a47;
  --secondary: #7a1e86;
  --tertiary: #4c1c66;
  --button-color: var(--secondary);
  --iconColor: #ffffff;
  --menu-icon-color: #ffffff;
  --shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  --mode-opacity: 0.1;
  --company-bg: brightness(0) invert(1);
  --swiper-pagination-color: var(--secondary);
  --swiper-pagination-bullet-inactive-color: var(--white);
  --swiper-pagination-bullet-inactive-opacity: 0.5;
  --linear-gradient: linear-gradient(var(--primary) 10%, var(--text-color) 40%);
  --input-bg: rgba(255, 255, 255, 0.05);
  --color-env: #7a1e86;
  --color-env2: #81208e;
  --color-flap: #4c1c66;
  --color-bg: #bd31d0;
  --headshot-bg: linear-gradient(var(--tertiary), var(--primary));
  margin: 0;
  padding: 0;
  background-color: var(--background-color) !important;
  padding: 0;
}

html:focus-within {
  scroll-behavior: smooth;
}
.app {
  display: flex;
  height: 100svh;
  width: 100%;
  overflow-y: visible;
  overflow-x: scroll;
  padding: 0;
  color: var(--text-color);
}
.app-container {
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1000;
}
.bg-wrap {
  height: 100lvh;
  width: 100%;
  position: relative;
  overflow-x: hidden;
}
.glass {
  border-radius: var(--borderRadius);
  background: var(--card-color);
  box-shadow: var(--shadow);
  position: relative;
}
body.dark .glass {
  border-radius: var(--borderRadius);
  background: rgba(255, 255, 255, 0.05);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: var(--shadow);
  position: relative;
}
body.dark .background {
  background: linear-gradient(-45deg, #342b77 20%, #110e27 60%);
  display: flex;
  height: 100lvh;
  width: 100%;
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  position: fixed;
  z-index: 0;
}
.background {
  background: linear-gradient(-45deg, #8399a2 50%, #c9c9c9 90%);
  display: flex;
  height: 100lvh;
  width: 100vw;
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  position: fixed;
  z-index: 0;
}

.no-scroll {
  overflow: hidden;
}
h1 {
  font-weight: 600;
}
h2 {
  font-weight: 500;
}
h3 {
  font-weight: 400;
}
h4 {
  font-weight: 400;
}
button {
  all: unset;
  cursor: pointer;
}
ul {
  all: unset;
}
li {
  all: unset;
}
li a {
  all: unset;
}
a {
  all: unset;
  text-decoration: underline;
  cursor: pointer;
}
input {
  all: unset;
}
button,
textarea,
input,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.MuiSvgIcon-root {
  font-size: inherit;
  .MuiSvgIcon-fontSizeInherit {
    font-size: inherit;
  }
}
@keyframes move-background {
  from {
    -webkit-transform: translate3d(0px, 0px, 0px);
  }
  to {
    -webkit-transform: translate3d(1000px, 0px, 0px);
  }
}
@-webkit-keyframes move-background {
  from {
    -webkit-transform: translate3d(0px, 0px, 0px);
  }
  to {
    -webkit-transform: translate3d(1000px, 0px, 0px);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
