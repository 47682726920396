$env-border-radius: 6px;
$env-width: 250px;
$env-height: 160px;

.envelope-container {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.sent-text {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  left: 50%;
  top: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
  animation: fadeIn 0.75s ease-in-out;
  animation-delay: 1.75s;
  animation-fill-mode: forwards;
}
.envelope {
  position: relative;
  width: $env-width;
  height: $env-height;
  border-bottom-left-radius: $env-border-radius;
  background-color: var(--color-flap);
  box-shadow: var(--shadow);
  animation: envelope-move 0.75s ease-in-out;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
}
.front-envelope {
  position: absolute;
  width: 0;
  height: 0;
  z-index: 3;
}
.flap {
  border-left: calc($env-width / 2) solid transparent;
  border-right: calc($env-width / 2) solid transparent;
  border-bottom: calc($env-height / 2) - 8 solid transparent; /* a little smaller */
  border-top: calc($env-height / 2) + 8 solid var(--color-flap); /* a little larger */
  transform-origin: top;
  pointer-events: none;
}
.pocket {
  border-left: calc($env-width / 2) solid var(--color-env);
  border-right: calc($env-width / 2) solid var(--color-env);
  border-bottom: calc($env-height / 2) solid var(--color-env2);
  border-top: calc($env-height / 2) solid transparent;
  border-bottom-left-radius: $env-border-radius;
  border-bottom-right-radius: $env-border-radius;
}
.letter {
  position: relative;
  background-color: #fff;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  height: 90%;
  top: 5%;
  border-radius: $env-border-radius;
  box-shadow: 0 2px 26px rgba(0, 0, 0, 0.12);
}
.letter:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 25%,
    rgba(215, 227, 239, 0.7) 55%,
    rgba(215, 227, 239, 1) 100%
  );
}
.words {
  position: absolute;
  left: 10%;
  width: 80%;
  height: 14%;
  background-color: #eeeff0;
}
.words.line1 {
  top: 15%;
  width: 20%;
  height: 7%;
}
.words.line2 {
  top: 30%;
}
.words.line3 {
  top: 50%;
}
.words.line4 {
  top: 70%;
}
.open .flap {
  transform: rotateX(180deg);
  transition: transform 0.4s ease, z-index 0.6s;
  z-index: 1;
}
.close .flap {
  transform: rotateX(0deg);
  transition: transform 0.4s 0.6s ease, z-index 1s;
  z-index: 5;
}
.close .letter {
  transform: translateY(0px);
  transition: transform 0.4s ease, z-index 1s;
  z-index: 1;
}
.open .letter {
  transform: translateY(-($env-height / 3));
  transition: transform 0.4s 0.6s ease, z-index 0.6s;
  z-index: 2;
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  animation-delay: 1.75s;
}
.checkmark {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
  animation-delay: 1.75s;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  animation-delay: 1.75s;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 40px #7ac142;
  }
}
@-webkit-keyframes envelope-move {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-500px);
    opacity: 0;
  }
}
@keyframes envelope-move {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-500px);
    opacity: 0;
  }
}
