body.dark .stars-container {
  width: 100%;
  height: 100%;
  perspective: 1px;
  -webkit-perspective: 1px;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
}

body.dark .stars {
  display: flex;
  width: 100vw;
  height: 100vh;
  position: relative;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  overflow: hidden;
  z-index: -1;
}

body.dark .star {
  box-shadow: 0px 0px 1px 1px rgba(255, 255, 255, 0.4);
  position: absolute;
  width: 1px;
  height: 1px;
  border-radius: 2px;
  background-color: white;
  animation: twinkle 6s ease-in-out infinite;
  @for $i from 1 through 50 {
    &:nth-child(#{$i}) {
      animation-delay: 1s * $i;
    }
  }
}
.shooting-stars {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4px;
    height: 4px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.1),
      0 0 0 8px rgba(255, 255, 255, 0.1), 0 0 20px rgba(255, 255, 255, 1);
    animation: shootingStar 10s linear infinite;
    animation-delay: 3.5s;
  }
  span::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 300px;
    height: 1px;
    background: linear-gradient(90deg, #fff, transparent);
  }
  span:nth-child(1) {
    top: 80px;
    right: 0;
    left: initial;
  }
}
@keyframes shootingStar {
  0% {
    transform: rotate(350deg) translateX(0);
    opacity: 1;
  }
  10% {
    opacity: 1;
  }
  20% {
    transform: rotate(350deg) translateX(-1500px);
    opacity: 0;
  }
  100% {
    transform: rotate(350deg) translateX(-1500px);
    opacity: 0;
  }
}
@keyframes twinkle {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
