.contact-container {
  display: flex;
  height: 35rem;
  padding: 2rem 1rem;
  overflow: hidden;
  width: calc(100% - 3rem);
}
.contact-form {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  width: 100%;
}
.form-label {
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: var(--text-color);
}
.form-input {
  font-family: inherit;
  max-width: 100%;
  border: 0;
  border-radius: 0%;
  border-bottom: 3px solid var(--secondary);
  outline: 0;
  font-size: 1.2rem;
  color: var(--text-color);
  padding: 0.5rem;
  margin-bottom: 1rem;
  background: none;
}
.form-submit-container {
  display: flex;
  width: 100%;
  margin-top: 1rem;
  height: fit-content;
  align-items: center;
  justify-content: center;
}
.form-submit-button {
  display: flex;
  height: fit-content;
  width: calc(100% - 1rem);
  column-gap: 1rem;
  padding: 0.5rem 0;
  justify-content: center;
  align-items: center;
  border-radius: var(--borderRadius);
  background-color: var(--tertiary);
  color: var(--white);
  h2 {
    line-height: 0%;
  }
}
.send-icon {
  font-size: var(--navIconSize);
  display: flex;
  justify-content: center;
  align-items: center;
}
