.header-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: fit-content;
  width: 100%;
  z-index: 1000;
  padding: 2rem 0 0 0;
  border-radius: var(--borderRadius);
  background: var(--card-color);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  -webkit-mask: radial-gradient(
      11rem 11rem at 50% 50%,
      transparent 50.05%,
      white 49.9%
    )
    bottom/ 100% 200%;
  mask: radial-gradient(11rem 11rem at 50% 50%, transparent 50.05%, white 49.9%)
    bottom/ 100% 200%;
}

.shadow {
  border-radius: var(--borderRadius);
  box-shadow: var(--shadow);
}
body.dark .header-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: fit-content;
  width: 100%;
  z-index: 1000;
  padding: 2rem 0 0 0;
  border-radius: var(--borderRadius);
  background: rgba(255, 255, 255, 0.05);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  -webkit-mask: radial-gradient(
      11rem 11rem at 50% 50%,
      transparent 50.05%,
      white 49.9%
    )
    bottom/ 100% 200%;
  mask: radial-gradient(11rem 11rem at 50% 50%, transparent 50.05%, white 49.9%)
    bottom/ 100% 200%;
}
.profile-image {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 9rem;
  height: 9rem;
  border-radius: 50%;
  z-index: 1000;
  margin-left: auto;
  margin-right: auto;
  top: -71px;
  left: 0;
  right: 0;
  bottom: 0;
  transform-style: preserve-3d;
  -webkit-animation: flip 15s infinite ease-in-out;
  animation: flip 15s infinite ease-in-out;
}
.header {
  display: flex;
  position: relative;
  width: calc(100% - 3rem);
  justify-content: center;
}
.header-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  border-radius: var(--borderRadius);
}
.header-links {
  display: flex;
  height: 4rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: var(--iconSize);
  padding: 0 4rem 0 4rem;
  border-radius: var(--borderRadius);
  background-color: var(--primary);
  color: var(--iconColor);
  a,
  button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.header-text {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-top: 4rem;
  line-height: 0%;
}
.front,
.back {
  position: absolute;
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.front {
  z-index: 2000;
}
.back {
  z-index: 1000;
  transform: rotateX(180deg);
}
.headshot-container {
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--headshot-bg);
  border: solid 3px var(--white);
}
.headshot {
  height: 95%;
  width: auto;
}
.logo-background {
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  background: linear-gradient(110deg, #474747, var(--black));
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 3px var(--secondary);
}
.dn-logo {
  transform: scale(0.3);
  display: flex;
  margin-left: 5px;
}
.more-modal {
  position: fixed;
  display: flex;
  z-index: 6000;
  height: fit-content;
  width: calc(100% - 3rem);
  max-width: 35rem;
  justify-content: space-between;
  column-gap: 2rem;
  align-items: baseline;
  border-radius: var(--borderRadius);
  color: var(--iconColor);
  background: var(--tertiary);
  transition: all 0.3s ease-in-out;
  padding: 1rem 3rem 0.7rem 3rem;
  box-shadow: var(--shadow);
}
.modal-open {
  bottom: 0;
  left: 50%;
  opacity: 1;
  -moz-transform: translate3d(0, 0, 0) translate(-50%, -50%);
  transform: translate3d(0, 0, 0) translate(-50%, -50%);
}
.modal-close {
  bottom: -12rem;
  left: 50%;
  opacity: 0;
  -moz-transform: translate3d(0, 0, 0) translate(-50%, -50%);
  transform: translate3d(0, 0, 0) translate(-50%, -50%);
}
.bg-open {
  top: 50%;
  left: 50%;
  -moz-transform: translate3d(0, 0, 0) translate(-50%, -50%);
  transform: translate3d(0, 0, 0) translate(-50%, -50%);
}
.bg-close {
  display: none;
}
.modal-bg {
  position: fixed;
  top: 50%;
  left: 50%;
  -moz-transform: translate3d(0, 0, 0) translate(-50%, -50%);
  transform: translate3d(0, 0, 0) translate(-50%, -50%);
  height: 100lvh;
  width: 100lvw;
  z-index: 4000;
}
.modal-close-btn {
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: var(--iconSize);
}
.share-icon-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  line-height: 0%;
  font-size: var(--navIconSize);
  text-decoration: none;
  p {
    font-size: 12px;
  }
}
.bubble {
  display: flex;
  position: relative;
  flex-direction: column;
  row-gap: 0.4rem;
  margin-bottom: 4rem;
  width: 50%;
  align-items: center;
  opacity: 0.7;
  transition: all ease-in-out;
  .text-bubble {
    display: flex;
    position: absolute;
    bottom: 50px;
    border-radius: 4rem;
    background: var(--white);
    color: var(--black);
    padding: 1rem;
    height: fit-content;
    min-width: 10rem;
  }
  .bubble-1 {
    display: flex;
    position: absolute;
    bottom: 0;
    right: 60px;
    border-radius: 50%;
    background: var(--white);
    height: 0.8rem;
    width: 0.8rem;
  }
  .bubble-2 {
    display: flex;
    position: absolute;
    bottom: 15px;
    right: 40px;
    border-radius: 50%;
    background: var(--white);
    height: 1.3rem;
    width: 1.3rem;
  }
  .bubble-3 {
    display: flex;
    position: absolute;
    bottom: 40px;
    right: 40px;
    border-radius: 50%;
    background: var(--white);
    height: 1.7rem;
    width: 1.7rem;
  }
}

@keyframes flip {
  0% {
    transform: rotateX(180deg);
  }
  10% {
    transform: rotateX(0deg);
  }
  60% {
    transform: rotateX(0deg);
  }
  70% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(180deg);
  }
}
