.mode-toggle-container {
  position: absolute;
  display: flex;
  top: 2rem;
  right: 2rem;
  width: fit-content;
  height: fit-content;
  justify-content: center;
  align-items: center;
  z-index: 5000;
}
.toggle-label {
  width: 90px;
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 60px;
  box-shadow: inset 0px 5px 14px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  transition: background, left 0.3s ease-in-out;
}
.toggle-label:after {
  content: "";
  width: 38px;
  height: 38px;
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  background: linear-gradient(180deg, var(--primary), var(--tertiary));
  border-radius: 50%;
  cursor: pointer;
  transition: background, left 0.3s ease-in-out;
}
.mode-toggle {
  width: 0;
  height: 0;
  display: hidden;
}
.mode-toggle:checked + .toggle-label::after {
  left: 50px;
  background: var(--primary);
}
.toggle-icons {
  display: flex;
  position: relative;
  width: 100%;
  font-size: var(--navIconSize);
  z-index: 7000;
  color: var(--white);
}
.moon {
  position: absolute;
  top: -12px;
  left: 8px;
}
.sun {
  position: absolute;
  top: -12px;
  right: 8px;
}
.icon-inactive {
  opacity: 0.6;
}
.icon-active {
  opacity: 1;
  color: var(--white);
}
.sun-color {
  color: var(--white);
}
.moon-color {
  color: var(--primary);
}
