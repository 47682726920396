.resume-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: fit-content;
  width: calc(100% - 3rem);
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  row-gap: 1.5rem;
  .hidden {
    opacity: 0%;
  }
  .visible {
    opacity: 100%;
    transition-delay: 0.4s;
  }
}
.resume-card {
  overflow: hidden;
  border-radius: var(--borderRadius);
  position: relative;
  display: flex;
  flex-direction: column;
  position: relative;
  height: fit-content;
  width: 100%;
  justify-content: left;
}
.bg-image-jpmc {
  height: 100%;
  width: 100%;
  position: absolute;
  background: url("https://dominicknitecki-media.s3.us-east-2.amazonaws.com/chase-logo.png");
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: -3.5rem -2rem;
  filter: var(--company-bg);
  opacity: var(--mode-opacity);
}
.bg-image-stn {
  height: 100%;
  width: 100%;
  position: absolute;
  background: url("https://dominicknitecki-media.s3.us-east-2.amazonaws.com/stantec-logo.png");
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: -3.5rem -2rem;
  filter: var(--company-bg);
  opacity: var(--mode-opacity);
}
.resume-closed {
  top: 0;
  left: 0;
  border-radius: var(--borderRadius);
}
.resume-open {
  top: 0;
  left: 100%;
  border-radius: var(--borderRadius);
}
.resume-card-bg {
  height: 100%;
  width: 100%;
  z-index: 2000;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  background-color: var(--tertiary);
}
.resume-card-front {
  display: flex;
  align-items: flex-start;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2000;
  padding-top: 1rem;
  padding-left: 1.5rem;
  line-height: 0%;
  flex-direction: column;
  -webkit-transition: opacity 0.2s ease;
  -o-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
  color: var(--white);
}
.resume-card-back {
  display: flex;
  width: 100%;
  height: 100%;
  line-height: 0%;
  flex-direction: column;
  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  z-index: 1000;
  overflow: hidden;
}
.resume-title {
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  line-height: 0%;
}
.resume-icon {
  display: flex;
  position: absolute;
  z-index: 3000;
  top: 50%;
  margin-top: -14.5px;
  right: 20px;
  font-size: var(--iconSize);
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.icon-open {
  transform: rotate(0);
  color: var(--menu-icon-color);
}
.icon-closed {
  transform: rotate(180deg);
  color: var(--white);
}
.resume-logo-container {
  display: flex;
  height: fit-content;
  width: 3rem;
  align-items: center;
  justify-content: center;
}
.resume-logo {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
}
.resume-header {
  display: flex;
  width: 100%;
  height: fit-content;
  column-gap: 0.75rem;
  align-items: center;
}
.resume-dates {
  display: flex;
  align-items: center;
}
