.slider-container {
  height: 100%;
  width: 100%;
  position: relative;
}
.swiper-container {
  height: fit-content;
  display: flex;
  width: 100%;
  height: fit-content;
  position: relative;
}
.swiper-slide {
  display: flex;
  width: fit-content;
  height: fit-content;
}
.portfolio-card {
  display: flex;
  height: 25rem;
  width: 16rem;
  flex-direction: column;
}
.portfolio-image-container {
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: var(--borderRadius);
}
.portfolio-image {
  display: flex;
  position: absolute;
}
.portfolio-title {
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  justify-content: center;
  align-items: center;
  height: 6rem;
  color: var(--active-color);
  background-color: var(--tertiary);
  overflow: hidden;
  text-align: center;
  h2 {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.finfetch-icon {
  top: -4.5rem;
  right: 4.5rem;
  width: 100%;
  height: auto;
  transform: scale(0.25);
  box-shadow: var(--shadow);
  border-radius: 50%;
}
.insurance-icon {
  top: -4.5rem;
  right: 4.5rem;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  transform: scale(0.25);
  box-shadow: var(--shadow);
  border-radius: 50%;
}
.finfetch {
  top: 120px;
  left: 10px;
  transform: scale(3);
  width: 100%;
  height: auto;
}
.insurance {
  top: 40px;
  width: 100%;
  transform: scale(2);
  height: auto;
  opacity: 0.8;
}
.photo-mock {
  display: flex;
  right: 0px;
  align-items: center;
  width: auto;
  height: 100%;
  opacity: 0.8;
}
.portfolio-logo {
  display: flex;
  position: absolute;
  z-index: 2000;
}
.item {
  height: fit-content;
  width: fit-content;
}
.inner-slider {
  display: flex;
  width: 100%;
  column-gap: 1.5rem;
}
.slider {
  display: flex;
  width: 100vw;
  padding: 0 1.5rem 0 1.5rem;
  overflow: visible;
}
.speedbump-bg {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  background-size: cover;
  overflow: hidden;
  background-color: var(--bgColor);
  z-index: 10000;
  justify-content: center;
  align-items: center;
  animation: fadeIn 200ms ease-in;
  -webkit-animation: fadeIn 200ms ease-in;
}
.speedbump-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 1rem;
  align-items: center;
  height: fit-content;
  width: 20rem;
  border-radius: var(--borderRadius);
  box-shadow: var(--shadow);
  background-color: var(--primary);
  padding: 1rem 2rem 1rem 2rem;
  color: var(--white);
}
.speedBump-close {
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  cursor: pointer;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: red;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.speedbump-icon {
  display: flex;
  font-size: var(--navIconSize);
}
.speedbump-text {
  display: flex;
  width: 100%;
  height: fit-content;
  justify-content: center;
  align-items: center;
  text-align: center;
  h3 {
    margin: 0%;
  }
}
.speedbump-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: 100%;
  column-gap: 1rem;
}
.speedbump-proceed {
  display: flex;
  height: fit-content;
  width: fit-content;
  column-gap: 0.5rem;
  padding: var(--buttonPadding);
  justify-content: center;
  align-items: center;
  border-radius: var(--borderRadius);
  background-color: var(--white);
  color: var(--primary);
  h3 {
    line-height: 0%;
  }
}
.speedbump-close {
  display: flex;
  height: fit-content;
  width: fit-content;
  padding: var(--buttonPadding);
  justify-content: center;
  align-items: center;
  border-radius: var(--borderRadius);
  h3 {
    line-height: 0%;
  }
}
