.startup-container {
  display: flex;
  height: 100lvh;
  width: 100svw;
  background-size: cover;
  overflow: hidden;
  background-color: var(--black);
  background-attachment: fixed;
  position: absolute;
  z-index: 2000;
  justify-content: center;
  align-items: center;
  -webkit-animation: fadeOut 4s linear forwards;
  animation: fadeOut 4s linear forwards;
}

.main-page-logo {
  display: flex;
  transform: scale(1);
  height: 20rem;
  width: auto;
}

@-webkit-keyframes fadeOut {
  0%,
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0%,
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
