.dots {
  width: 60px;
  aspect-ratio: 3;
  --gradient: no-repeat
    radial-gradient(
      circle closest-side,
      var(--white) 90%,
      rgba(255, 255, 255, 0)
    );
  background: var(--gradient) 0% 50%, var(--gradient) 50% 50%,
    var(--gradient) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: dots 1s infinite linear;
}
@keyframes dots {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }
  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }
  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%;
  }
  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%;
  }
}
